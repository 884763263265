.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .btn {
    font-size: 2em;
  }
}
