.ReactTable {
  z-index: 10;
}

.table-header-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  > div.float-left:not(:last-child) {
    margin-right: 5px;
  }

  > div.float-right:not(:first-child) {
    margin-left: 5px;
  }

  .fill {
    flex: 1;
  }
}

.search-results-table {
  padding: 10px;
}
