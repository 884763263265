.features {
  * {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
}

.features-sidebar {
  flex: 3;
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 10px;

  @media(max-width: 991px) {
    display: none;
  }
}

.features > .feature-slider {
  padding: 8px;
  margin-bottom: 5px;

  &:not(:last-child) {
    border-bottom: 1px solid #e2e2e2;
  }
}
