.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #919191;
  opacity: 0.3;
  z-index: 300;
  inset: 0;
}
