.info-footer {
  background-color: $dark;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: $font-size-sm;
}

.inactive-feature-slider-label {
  text-decoration: line-through;
}
